export default {
    state: {
        date: '',
        time: ''
    },
    reducers: {
        setDate: (state, payload) => ({
            ...state,
            date: payload,
        }),
        setTime: (state, payload) => ({
            ...state,
            time: payload,
        }),
    }
}
