export default {
    state: new Set(),
    reducers: {
        add: (state, name) => {
            state.add(name)
            return new Set(state)
        },
        delete: (state, name) => {
            state.delete(name)
            return new Set(state)
        },
        edit: (state, {origin, name}) => {
            const res = Array.from(state)
            const eq = res.findIndex(r => r === origin)
            res.splice(eq, 1, name)
            return new Set(res)
        }
    }
}
