import axios from '@/utils/axios'
import urls from '@/urls'
import moment from 'moment'

export default {
    state: {
        id: 0,
        detail: {},
        range: [],
        stock: ''
    },
    reducers: {
        setId: (state, id) => {
            return {
                ...state,
                id
            }
        },
        setDetail: (state, detail) => {
            return {
                ...state,
                detail
            }
        },
        setRange: (state, range) => {
            return {
                ...state,
                range
            }
        },
        setStock: (state, stock) => {
            return {
                ...state,
                stock
            }
        }
    },
    effects: {
        async fetchData(id, rootState) {
            const res = await axios.get(urls.getReservationMuseumById, {
                params: {
                    museumId: id
                }
            })
            this.setDetail(res.data)
        },
        async getStock({id, date, time = ''}, rootState) {
            const res = await axios.post(urls.getRestStock, {
                appointmentDate: date ? moment(new Date(date)).format('YYYY-MM-DD') : '',
                arrivalTime: time,
                museumId: id
            })
            this.setStock(res.data)
        },
        async fetchRangeTime(id, rootState) {
            const res = await axios.get(urls.getReservationMuseumArrivalRange, {
                params: {
                    museumId: id
                }
            })
            this.setRange(res.data.map(r => ({
                value: r.timeRange,
                label: r.timeRange
            })))
        }
    }
}
