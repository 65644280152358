import {env} from '../package.json'

// 如果package配了生产 不做代理转发 否则去setupProxy修改环境
// 结论 部署到线上 package env必须配production
// 如果是本地开发 修改setupProxy的target
const prefix = env === 'production' ? 'https://fly.bowu66.com' : ''

export default {
    // 第一部分 基础功能
    getCode: `${prefix}/h5/reservation/getVerificationCode`, // 获取验证码 ✔
    login: `${prefix}/h5/reservation/loginH5`, // 登录 ✔
    checkHealthCode: `${prefix}/h5/reservation/checkHealthCode`, // 检验健康吗
    checkIdCard: `${prefix}/h5/reservation/checkIdCard`, // 校验身份证
    getHealthClaim: `${prefix}/h5/reservation/getHealthClaim`, // 获取健康申明 ✔
    getReservationMuseumArrivalRange: `${prefix}/h5/reservation/getReservationMuseumArrivalRange`, // 获取预约到馆时间 ✔
    getIdentityForH5: `${prefix}/h5/reservation/getIdentityForH5`, // 获取以前的信息记录复制  ✔
    getReservationMuseumById: `${prefix}/h5/reservation/getReservationMuseumById`, // 获取预约博物馆 ✔
    getReservationPeopleForH5: `${prefix}/h5/reservation/getReservationPeopleForH5`, // 我的预约 ✔
    saveReservationPeopleForH5: `${prefix}/h5/reservation/saveReservationPeopleForH5`, // 立即预约 ✔
    cancelH5Appointment: `${prefix}/h5/reservation/cancelH5Appointment`, // 取消预约  ✔
    h5WriteOff: `${prefix}/h5/reservation/h5WriteOff`, // 核验 ✔
    saveRegistrationIntent: `${prefix}/h5/reservation/saveRegistrationIntent`, // 意向登记
    deleteH5Reservation: `${prefix}/h5/reservation/deleteH5Reservation`, // 删除预约,
    getReservationDetail: `${prefix}/h5/reservation/getReservationDetail`, // 预约详情
    getRestStock: `${prefix}/h5/reservation/getRestStock`, // 获取库存 
}
