import sharks from './sharks'
import global from './global'
import persistence from './persistence'
import customers from './customers'
import localData from './localData'
import selected from './selected'

export default {
    sharks,
    global,
    persistence,
    customers,
    localData,
    selected
}