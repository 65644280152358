import urls from '@/urls'
import { Toast } from 'antd-mobile'
import axios from 'axios'
import md5 from 'md5'
import localforage from 'localforage'
import history from './history'

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
    //   baseURL: 'http://www.zone0596.xyz',
    headers: {
        'Content-Type': 'application/json'
    }
}

const _axios = axios.create(config)

const appKey = '8875a184d786a8b09bc0a7733586f34e'
const appSecret = '89c01fc82d2012cdeec069f7d830cf1a'

_axios.interceptors.request.use(
    async config => {
        const timestamp = +new Date()
        config.headers.timestamp = timestamp
        config.headers.sign = md5(appKey + appSecret + timestamp)
        const token = await localforage.getItem('token')
        if ([urls.login, urls.register].includes(config.url)) {
        } else {
            if (token) {
                config.headers.signature = token // 将token设置成请求头
                if (config.method === 'get') {
                    config.params = {
                        ...config.params,
                        token
                    }
                } else {
                    config.data = {
                        ...config.data,
                        token
                    }
                }
            }
            // else {
            //     history.replace('/login')
            // }
        }

        // Do something before request is sent
        return config
    },
    error => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // errno 0 success
        // if ([4005, 5000].includes(response.data.code)) {
        if (response.data.msg === '登录已失效，请先登录') {
            // localforage.clear().then(() => {
            //     history.replace(`/login?callback=${encodeURIComponent(global.location.href)}`)
            //     throw 'token 失效'
            // })
            history.replace(`/login?callback=${encodeURIComponent(global.location.href)}`)
            throw 'token 失效'
        } else if (response.data.code !== 200) {
            Toast.fail(response.data.msg, 2)
            throw response.data.msg
        }
        // Do something with response data
        return response.data
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default _axios
