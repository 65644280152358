export default {
    state: [],
    reducers: {
        add: (state, payload) => {
            state.push(payload)
            return state
        },
        del: (state, name) => {
            return state.filter(r => r.name !== name)
        },
        edit: (state, {customer, index}) => {
            state.splice(index, 1, customer)
            return state
        }
    }
}
